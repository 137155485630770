<template lang="pug">
.card
    .card-body
        h2 Livestream
            iframe(width="600" height="337" src="https://subsplash.com/+p4y7/embed/mi/*next-live?audio&video" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen)
        h2 Previous streams
            iframe(width="600" height="800" src="https://subsplash.com/+p4y7/lb/ms/+y42zyf3?embed&1665854077751")
</template>

<script>
export default {

}
</script>

<style>

</style>